



















import './scss/BaseSidebarOpenButton.scss';
import { Component, Vue } from 'vue-property-decorator';
import { sidebarNamespace } from '@store/modules/sidebar';
import { SidebarMutationTypes } from '@store/modules/sidebar/Types';
import { makeBlur } from '@utility/makeBlur';

@Component
export default class SidebarOpenBtn extends Vue {
    @sidebarNamespace.Getter('sidebarShow') sidebarShow!: boolean;
    @sidebarNamespace.Mutation(SidebarMutationTypes.M_SET_SIDEBAR_SHOW) setSidebarShow!: (payload: boolean) => void

    sidebarToggle() {
        this.setSidebarShow(!this.sidebarShow);
        makeBlur();
    }
}
